






































































































import { Component, Vue } from "vue-property-decorator";
import {
  appointmentList,
  // activityAdd,
  // activityStatus,
  // activityEdit,
} from "@/apis";
import { parseTime } from "@/utils";
//import Edit from "@/views/ActionCenter/ActivityManagement/module/Edit.vue";
@Component({
  components: {},
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class AppointmentList extends Vue {
  tableData: Array<any> = [];
  newForm = {
    start_createdate: 1610595761000,
    end_createdate: 1610595761000,
    patient_name: "用", // 患者姓名
    appointment_state: 103, // 状态，见需求
  };
  formList = {
    start_createdate: "",
    end_createdate: "",
    patient_name: "",
    appointment_state: "",
  };
  preDate = [];
  orderState = {
    "101": "待审核",
    "102": "待程控",
    "108": "程控当天",
    "104": "已完成",
    "109": "已完成",
    103: "已退款",
    105: "已退款",
    106: "已退款",
    107: "已退款",
    110: "已退款",
  };
  // 分页
  form = {
    cur_page: 1,
    page_size: 10,
  };

  totalSize = 0;
  activityState = ["筹备中", "进行中", "已完成"];
  created() {
    this.requestList();
  }
  viewDetail(v: number) {
    console.log(v);
    this.$router.push({
      path: "/elemedirecord/patientdata/detail",
      // query: { id: v as any },
    });
  }
  handleSizeChange(val: number) {
    console.log(`每页 ${val} 条`);
    this.form.cur_page = 1;
    this.form.page_size = val;
    this.requestList();
  }

  handleCurrentChange(val: number) {
    console.log(`当前页: ${val}`);
    this.form.cur_page = val;
    this.requestList();
  }
  // 活动查询
  async requestList() {
    const result = await appointmentList(this.form);
    this.tableData = result.data;
    this.totalSize = result.page.total_size;
    console.log(result);
  }

  // 活动增加
  // async activityAdd() {
  //   const result = await activityAdd(this.formList);
  //   this.tableData = result.data;
  //   console.log(result);
  // }

  // 活动编辑
  // async activityEdit() {
  //   const result = await activityEdit(this.formList);
  //   this.tableData = result.data;
  //   console.log(result.data);
  // }

  // 活动状态改变
  // async activityStatus() {
  //   const temp = {
  //     id: 1, // 活动id
  //     status: 1, // 变更的状态
  //   };
  //   const result = await activityStatus(this.formList);
  //   this.tableData = result.data;
  //   console.log(result);
  // }

  //重置
  searchReset() {
    this.formList = {
      start_createdate: "",
      end_createdate: "",
      patient_name: "",
      appointment_state: "",
    };
  }
  //EleMediRecord
  async searchButton() {
    const temp = {} as any;
    const Api: any = this.formList; //重新定义了下循环的对象
    this.formList.start_createdate = this.preDate[0];
    this.formList.end_createdate = this.preDate[1];
    for (const key in Api) {
      if (Api[key]) {
        temp[key] = Api[key];
      }
    }
    if (Object.keys(temp).length) {
      const result = await appointmentList({ ...temp, ...this.form });
      this.tableData = result.data;
      this.form.cur_page = 1;
      this.totalSize = result.page.total_size;
    } else {
      this.$message.warning("请输入搜索条件");
    }
  }
  editClick(v: number) {
    console.log(v);
    // this.$refs.edit.dialogFormVisible = true;
    //   this.$refs.edit.form = v;
  }
}
